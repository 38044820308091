import roboto from "gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

const fontFaces = `
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 200 1000;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}
`;

// merge will deeply merge custom values with the
// unknown theme's defaults
export default merge(roboto, {
  // example base theme from @theme-ui/presets
  breakpoints: ["40em", "63em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Nunito Sans", sans-serif',
    heading: '"Nunito Sans", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 22, 28, 45],
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
    extraBlack: 1000,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.4,
  },



  colors: {
    // light: {
    //     primary: "#785A0B",
    //     surfaceTint: "#785A0B",
    //     onPrimary: "#FFFFFF",
    //     primaryContainer: "#FFDF9E",
    //     onPrimaryContainer: "#261A00",
    //     secondary: "#6B5D3F",
    //     onSecondary: "#FFFFFF",
    //     secondaryContainer: "#F5E0BB",
    //     onSecondaryContainer: "#241A04",
    //     tertiary: "#4A6547",
    //     onTertiary: "#FFFFFF",
    //     tertiaryContainer: "#CCEBC4",
    //     onTertiaryContainer: "#072008",
    //     error: "#BA1A1A",
    //     onError: "#FFFFFF",
    //     errorContainer: "#FFDAD6",
    //     onErrorContainer: "#410002",
    //     background: "#FFF8F2",
    //     onBackground: "#1F1B13",
    //     surface: "#FFF8F2",
    //     onSurface: "#1F1B13",
    //     surfaceVariant: "#EDE1CF",
    //     onSurfaceVariant: "#4D4639",
    //     outline: "#7F7667",
    //     outlineVariant: "#D0C5B4",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#353027",
    //     inverseOnSurface: "#F9EFE2",
    //     inversePrimary: "#E9C16C",
    //     primaryFixed: "#FFDF9E",
    //     onPrimaryFixed: "#261A00",
    //     primaryFixedDim: "#E9C16C",
    //     onPrimaryFixedVariant: "#5B4300",
    //     secondaryFixed: "#F5E0BB",
    //     onSecondaryFixed: "#241A04",
    //     secondaryFixedDim: "#D8C4A0",
    //     onSecondaryFixedVariant: "#52452A",
    //     tertiaryFixed: "#CCEBC4",
    //     onTertiaryFixed: "#072008",
    //     tertiaryFixedDim: "#B0CFA9",
    //     onTertiaryFixedVariant: "#334D30",
    //     surfaceDim: "#E2D9CC",
    //     surfaceBright: "#FFF8F2",
    //     surfaceContainerLowest: "#FFFFFF",
    //     surfaceContainerLow: "#FCF2E5",
    //     surfaceContainer: "#F7ECDF",
    //     surfaceContainerHigh: "#F1E7D9",
    //     surfaceContainerHighest: "#EBE1D4"
    // },
    // lightMediumContrast: {
    //     primary: "#563F00",
    //     surfaceTint: "#785A0B",
    //     onPrimary: "#FFFFFF",
    //     primaryContainer: "#907023",
    //     onPrimaryContainer: "#FFFFFF",
    //     secondary: "#4E4126",
    //     onSecondary: "#FFFFFF",
    //     secondaryContainer: "#837354",
    //     onSecondaryContainer: "#FFFFFF",
    //     tertiary: "#2F492D",
    //     onTertiary: "#FFFFFF",
    //     tertiaryContainer: "#607C5B",
    //     onTertiaryContainer: "#FFFFFF",
    //     error: "#8C0009",
    //     onError: "#FFFFFF",
    //     errorContainer: "#DA342E",
    //     onErrorContainer: "#FFFFFF",
    //     background: "#FFF8F2",
    //     onBackground: "#1F1B13",
    //     surface: "#FFF8F2",
    //     onSurface: "#1F1B13",
    //     surfaceVariant: "#EDE1CF",
    //     onSurfaceVariant: "#494235",
    //     outline: "#665E50",
    //     outlineVariant: "#837A6B",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#353027",
    //     inverseOnSurface: "#F9EFE2",
    //     inversePrimary: "#E9C16C",
    //     primaryFixed: "#907023",
    //     onPrimaryFixed: "#FFFFFF",
    //     primaryFixedDim: "#755708",
    //     onPrimaryFixedVariant: "#FFFFFF",
    //     secondaryFixed: "#837354",
    //     onSecondaryFixed: "#FFFFFF",
    //     secondaryFixedDim: "#695A3D",
    //     onSecondaryFixedVariant: "#FFFFFF",
    //     tertiaryFixed: "#607C5B",
    //     onTertiaryFixed: "#FFFFFF",
    //     tertiaryFixedDim: "#486344",
    //     onTertiaryFixedVariant: "#FFFFFF",
    //     surfaceDim: "#E2D9CC",
    //     surfaceBright: "#FFF8F2",
    //     surfaceContainerLowest: "#FFFFFF",
    //     surfaceContainerLow: "#FCF2E5",
    //     surfaceContainer: "#F7ECDF",
    //     surfaceContainerHigh: "#F1E7D9",
    //     surfaceContainerHighest: "#EBE1D4"
    // },
    // lightHighContrast: {
    //     primary: "#2E2000",
    //     surfaceTint: "#785A0B",
    //     onPrimary: "#FFFFFF",
    //     primaryContainer: "#563F00",
    //     onPrimaryContainer: "#FFFFFF",
    //     secondary: "#2B2108",
    //     onSecondary: "#FFFFFF",
    //     secondaryContainer: "#4E4126",
    //     onSecondaryContainer: "#FFFFFF",
    //     tertiary: "#0F270F",
    //     onTertiary: "#FFFFFF",
    //     tertiaryContainer: "#2F492D",
    //     onTertiaryContainer: "#FFFFFF",
    //     error: "#4E0002",
    //     onError: "#FFFFFF",
    //     errorContainer: "#8C0009",
    //     onErrorContainer: "#FFFFFF",
    //     background: "#FFF8F2",
    //     onBackground: "#1F1B13",
    //     surface: "#FFF8F2",
    //     onSurface: "#000000",
    //     surfaceVariant: "#EDE1CF",
    //     onSurfaceVariant: "#292318",
    //     outline: "#494235",
    //     outlineVariant: "#494235",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#353027",
    //     inverseOnSurface: "#FFFFFF",
    //     inversePrimary: "#FFEAC3",
    //     primaryFixed: "#563F00",
    //     onPrimaryFixed: "#FFFFFF",
    //     primaryFixedDim: "#3B2A00",
    //     onPrimaryFixedVariant: "#FFFFFF",
    //     secondaryFixed: "#4E4126",
    //     onSecondaryFixed: "#FFFFFF",
    //     secondaryFixedDim: "#372B12",
    //     onSecondaryFixedVariant: "#FFFFFF",
    //     tertiaryFixed: "#2F492D",
    //     onTertiaryFixed: "#FFFFFF",
    //     tertiaryFixedDim: "#193218",
    //     onTertiaryFixedVariant: "#FFFFFF",
    //     surfaceDim: "#E2D9CC",
    //     surfaceBright: "#FFF8F2",
    //     surfaceContainerLowest: "#FFFFFF",
    //     surfaceContainerLow: "#FCF2E5",
    //     surfaceContainer: "#F7ECDF",
    //     surfaceContainerHigh: "#F1E7D9",
    //     surfaceContainerHighest: "#EBE1D4"
    // },

    primary: "#E9C16C",
    surfaceTint: "#E9C16C",
    onPrimary: "#402D00",
    primaryContainer: "#5B4300",
    onPrimaryContainer: "#FFDF9E",
    secondary: "#D8C4A0",
    onSecondary: "#3B2F15",
    secondaryContainer: "#52452A",
    onSecondaryContainer: "#F5E0BB",
    tertiary: "#B0CFA9",
    onTertiary: "#1D361C",
    tertiaryContainer: "#334D30",
    onTertiaryContainer: "#CCEBC4",
    error: "#FFB4AB",
    onError: "#690005",
    errorContainer: "#93000A",
    onErrorContainer: "#FFDAD6",
    background: "#17130B",
    onBackground: "#EBE1D4",
    surface: "#17130B",
    onSurface: "#EBE1D4",
    surfaceVariant: "#4D4639",
    onSurfaceVariant: "#D0C5B4",
    outline: "#998F80",
    outlineVariant: "#4D4639",
    shadow: "#000000",
    scrim: "#000000",
    inverseSurface: "#EBE1D4",
    inverseOnSurface: "#353027",
    inversePrimary: "#785A0B",
    primaryFixed: "#FFDF9E",
    onPrimaryFixed: "#261A00",
    primaryFixedDim: "#E9C16C",
    onPrimaryFixedVariant: "#5B4300",
    secondaryFixed: "#F5E0BB",
    onSecondaryFixed: "#241A04",
    secondaryFixedDim: "#D8C4A0",
    onSecondaryFixedVariant: "#52452A",
    tertiaryFixed: "#CCEBC4",
    onTertiaryFixed: "#072008",
    tertiaryFixedDim: "#B0CFA9",
    onTertiaryFixedVariant: "#334D30",
    surfaceDim: "#17130B",
    surfaceBright: "#3E382F",
    surfaceContainerLowest: "#110E07",
    surfaceContainerLow: "#1F1B13",
    surfaceContainer: "#231F17",
    surfaceContainerHigh: "#2E2921",
    surfaceContainerHighest: "#39342B",
    text:"onSurface"

    // darkMediumContrast: {
    //     primary: "#EEC570",
    //     surfaceTint: "#E9C16C",
    //     onPrimary: "#1F1500",
    //     primaryContainer: "#AF8C3D",
    //     onPrimaryContainer: "#000000",
    //     secondary: "#DCC8A4",
    //     onSecondary: "#1E1501",
    //     secondaryContainer: "#A08F6E",
    //     onSecondaryContainer: "#000000",
    //     tertiary: "#B4D3AD",
    //     onTertiary: "#031B05",
    //     tertiaryContainer: "#7B9876",
    //     onTertiaryContainer: "#000000",
    //     error: "#FFBAB1",
    //     onError: "#370001",
    //     errorContainer: "#FF5449",
    //     onErrorContainer: "#000000",
    //     background: "#17130B",
    //     onBackground: "#EBE1D4",
    //     surface: "#17130B",
    //     onSurface: "#FFFAF7",
    //     surfaceVariant: "#4D4639",
    //     onSurfaceVariant: "#D5C9B8",
    //     outline: "#ACA291",
    //     outlineVariant: "#8B8273",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#EBE1D4",
    //     inverseOnSurface: "#2E2921",
    //     inversePrimary: "#5D4400",
    //     primaryFixed: "#FFDF9E",
    //     onPrimaryFixed: "#191000",
    //     primaryFixedDim: "#E9C16C",
    //     onPrimaryFixedVariant: "#473300",
    //     secondaryFixed: "#F5E0BB",
    //     onSecondaryFixed: "#191000",
    //     secondaryFixedDim: "#D8C4A0",
    //     onSecondaryFixedVariant: "#41351B",
    //     tertiaryFixed: "#CCEBC4",
    //     onTertiaryFixed: "#001602",
    //     tertiaryFixedDim: "#B0CFA9",
    //     onTertiaryFixedVariant: "#233C21",
    //     surfaceDim: "#17130B",
    //     surfaceBright: "#3E382F",
    //     surfaceContainerLowest: "#110E07",
    //     surfaceContainerLow: "#1F1B13",
    //     surfaceContainer: "#231F17",
    //     surfaceContainerHigh: "#2E2921",
    //     surfaceContainerHighest: "#39342B"
    // },
    // darkHighContrast: {
    //     primary: "#FFFAF7",
    //     surfaceTint: "#E9C16C",
    //     onPrimary: "#000000",
    //     primaryContainer: "#EEC570",
    //     onPrimaryContainer: "#000000",
    //     secondary: "#FFFAF7",
    //     onSecondary: "#000000",
    //     secondaryContainer: "#DCC8A4",
    //     onSecondaryContainer: "#000000",
    //     tertiary: "#F1FFEA",
    //     onTertiary: "#000000",
    //     tertiaryContainer: "#B4D3AD",
    //     onTertiaryContainer: "#000000",
    //     error: "#FFF9F9",
    //     onError: "#000000",
    //     errorContainer: "#FFBAB1",
    //     onErrorContainer: "#000000",
    //     background: "#17130B",
    //     onBackground: "#EBE1D4",
    //     surface: "#17130B",
    //     onSurface: "#FFFFFF",
    //     surfaceVariant: "#4D4639",
    //     onSurfaceVariant: "#FFFAF7",
    //     outline: "#D5C9B8",
    //     outlineVariant: "#D5C9B8",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#EBE1D4",
    //     inverseOnSurface: "#000000",
    //     inversePrimary: "#382700",
    //     primaryFixed: "#FFE4AF",
    //     onPrimaryFixed: "#000000",
    //     primaryFixedDim: "#EEC570",
    //     onPrimaryFixedVariant: "#1F1500",
    //     secondaryFixed: "#F9E4BF",
    //     onSecondaryFixed: "#000000",
    //     secondaryFixedDim: "#DCC8A4",
    //     onSecondaryFixedVariant: "#1E1501",
    //     tertiaryFixed: "#D0F0C8",
    //     onTertiaryFixed: "#000000",
    //     tertiaryFixedDim: "#B4D3AD",
    //     onTertiaryFixedVariant: "#031B05",
    //     surfaceDim: "#17130B",
    //     surfaceBright: "#3E382F",
    //     surfaceContainerLowest: "#110E07",
    //     surfaceContainerLow: "#1F1B13",
    //     surfaceContainer: "#231F17",
    //     surfaceContainerHigh: "#2E2921",
    //     surfaceContainerHighest: "#39342B"
    // }
  },
  palettes: {
    primary: {
      0: "#000000",
      5: "#180F00",
      10: "#261A00",
      15: "#322300",
      20: "#402D00",
      25: "#4D3800",
      30: "#5B4300",
      35: "#6A4E00",
      40: "#795900",
      50: "#987100",
      60: "#B68A0F",
      70: "#D4A42E",
      80: "#F2BF48",
      90: "#FFDF9E",
      95: "#FFEFD4",
      98: "#FFF8F2",
      99: "#FFFBFF",
      100: "#FFFFFF"
    },
    secondary: {
      0: "#000000",
      5: "#180F00",
      10: "#241A04",
      15: "#2F240C",
      20: "#3B2F15",
      25: "#463A1F",
      30: "#52452A",
      35: "#5F5134",
      40: "#6B5D3F",
      50: "#857556",
      60: "#A08F6E",
      70: "#BBA987",
      80: "#D8C4A0",
      90: "#F5E0BB",
      95: "#FFEFD4",
      98: "#FFF8F2",
      99: "#FFFBFF",
      100: "#FFFFFF"
    },
    tertiary: {
      0: "#000000",
      5: "#001502",
      10: "#082008",
      15: "#122B12",
      20: "#1D361C",
      25: "#284126",
      30: "#334D31",
      35: "#3F593B",
      40: "#4A6547",
      50: "#627E5E",
      60: "#7C9876",
      70: "#96B38F",
      80: "#B0CFA9",
      90: "#CCEBC4",
      95: "#DAF9D2",
      98: "#ECFFE4",
      99: "#F6FFF0",
      100: "#FFFFFF"
    },
    neutral: {
      0: "#000000",
      5: "#13110C",
      10: "#1E1B16",
      15: "#292520",
      20: "#34302A",
      25: "#3F3B35",
      30: "#4A4640",
      35: "#56524B",
      40: "#625E57",
      50: "#7C766F",
      60: "#969088",
      70: "#B1AAA2",
      80: "#CCC5BD",
      90: "#E9E1D9",
      95: "#F7EFE7",
      98: "#FFF8F2",
      99: "#FFFBFF",
      100: "#FFFFFF"
    },
    neutralVariant: {
      0: "#000000",
      5: "#151006",
      10: "#201B10",
      15: "#2B251A",
      20: "#363024",
      25: "#413B2E",
      30: "#4D4639",
      35: "#595144",
      40: "#655D4F",
      50: "#7F7667",
      60: "#998F80",
      70: "#B4AA99",
      80: "#D0C5B4",
      90: "#EDE1CF",
      95: "#FBEFDD",
      98: "#FFF8F2",
      99: "#FFFBFF",
      100: "#FFFFFF"
    }
  },








  //   colors: {
  //     light: {
  //         primary: "#785A0B",
  //         surfaceTint: "#785A0B",
  //         onPrimary: "#FFFFFF",
  //         primaryContainer: "#FFDF9E",
  //         onPrimaryContainer: "#261A00",
  //         secondary: "#6B5D3F",
  //         onSecondary: "#FFFFFF",
  //         secondaryContainer: "#F5E0BB",
  //         onSecondaryContainer: "#241A04",
  //         tertiary: "#4A6547",
  //         onTertiary: "#FFFFFF",
  //         tertiaryContainer: "#CCEBC4",
  //         onTertiaryContainer: "#072008",
  //         error: "#BA1A1A",
  //         onError: "#FFFFFF",
  //         errorContainer: "#FFDAD6",
  //         onErrorContainer: "#410002",
  //         background: "#FFF8F2",
  //         onBackground: "#1F1B13",
  //         surface: "#FFF8F2",
  //         onSurface: "#1F1B13",
  //         surfaceVariant: "#EDE1CF",
  //         onSurfaceVariant: "#4D4639",
  //         outline: "#7F7667",
  //         outlineVariant: "#D0C5B4",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#353027",
  //         inverseOnSurface: "#F9EFE2",
  //         inversePrimary: "#E9C16C",
  //         primaryFixed: "#FFDF9E",
  //         onPrimaryFixed: "#261A00",
  //         primaryFixedDim: "#E9C16C",
  //         onPrimaryFixedVariant: "#5B4300",
  //         secondaryFixed: "#F5E0BB",
  //         onSecondaryFixed: "#241A04",
  //         secondaryFixedDim: "#D8C4A0",
  //         onSecondaryFixedVariant: "#52452A",
  //         tertiaryFixed: "#CCEBC4",
  //         onTertiaryFixed: "#072008",
  //         tertiaryFixedDim: "#B0CFA9",
  //         onTertiaryFixedVariant: "#334D30",
  //         surfaceDim: "#E2D9CC",
  //         surfaceBright: "#FFF8F2",
  //         surfaceContainerLowest: "#FFFFFF",
  //         surfaceContainerLow: "#FCF2E5",
  //         surfaceContainer: "#F7ECDF",
  //         surfaceContainerHigh: "#F1E7D9",
  //         surfaceContainerHighest: "#EBE1D4"
  //     },
  //     lightMediumContrast: {
  //         primary: "#563F00",
  //         surfaceTint: "#785A0B",
  //         onPrimary: "#FFFFFF",
  //         primaryContainer: "#907023",
  //         onPrimaryContainer: "#FFFFFF",
  //         secondary: "#4E4126",
  //         onSecondary: "#FFFFFF",
  //         secondaryContainer: "#837354",
  //         onSecondaryContainer: "#FFFFFF",
  //         tertiary: "#2F492D",
  //         onTertiary: "#FFFFFF",
  //         tertiaryContainer: "#607C5B",
  //         onTertiaryContainer: "#FFFFFF",
  //         error: "#8C0009",
  //         onError: "#FFFFFF",
  //         errorContainer: "#DA342E",
  //         onErrorContainer: "#FFFFFF",
  //         background: "#FFF8F2",
  //         onBackground: "#1F1B13",
  //         surface: "#FFF8F2",
  //         onSurface: "#1F1B13",
  //         surfaceVariant: "#EDE1CF",
  //         onSurfaceVariant: "#494235",
  //         outline: "#665E50",
  //         outlineVariant: "#837A6B",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#353027",
  //         inverseOnSurface: "#F9EFE2",
  //         inversePrimary: "#E9C16C",
  //         primaryFixed: "#907023",
  //         onPrimaryFixed: "#FFFFFF",
  //         primaryFixedDim: "#755708",
  //         onPrimaryFixedVariant: "#FFFFFF",
  //         secondaryFixed: "#837354",
  //         onSecondaryFixed: "#FFFFFF",
  //         secondaryFixedDim: "#695A3D",
  //         onSecondaryFixedVariant: "#FFFFFF",
  //         tertiaryFixed: "#607C5B",
  //         onTertiaryFixed: "#FFFFFF",
  //         tertiaryFixedDim: "#486344",
  //         onTertiaryFixedVariant: "#FFFFFF",
  //         surfaceDim: "#E2D9CC",
  //         surfaceBright: "#FFF8F2",
  //         surfaceContainerLowest: "#FFFFFF",
  //         surfaceContainerLow: "#FCF2E5",
  //         surfaceContainer: "#F7ECDF",
  //         surfaceContainerHigh: "#F1E7D9",
  //         surfaceContainerHighest: "#EBE1D4"
  //     },
  //     lightHighContrast: {
  //         primary: "#2E2000",
  //         surfaceTint: "#785A0B",
  //         onPrimary: "#FFFFFF",
  //         primaryContainer: "#563F00",
  //         onPrimaryContainer: "#FFFFFF",
  //         secondary: "#2B2108",
  //         onSecondary: "#FFFFFF",
  //         secondaryContainer: "#4E4126",
  //         onSecondaryContainer: "#FFFFFF",
  //         tertiary: "#0F270F",
  //         onTertiary: "#FFFFFF",
  //         tertiaryContainer: "#2F492D",
  //         onTertiaryContainer: "#FFFFFF",
  //         error: "#4E0002",
  //         onError: "#FFFFFF",
  //         errorContainer: "#8C0009",
  //         onErrorContainer: "#FFFFFF",
  //         background: "#FFF8F2",
  //         onBackground: "#1F1B13",
  //         surface: "#FFF8F2",
  //         onSurface: "#000000",
  //         surfaceVariant: "#EDE1CF",
  //         onSurfaceVariant: "#292318",
  //         outline: "#494235",
  //         outlineVariant: "#494235",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#353027",
  //         inverseOnSurface: "#FFFFFF",
  //         inversePrimary: "#FFEAC3",
  //         primaryFixed: "#563F00",
  //         onPrimaryFixed: "#FFFFFF",
  //         primaryFixedDim: "#3B2A00",
  //         onPrimaryFixedVariant: "#FFFFFF",
  //         secondaryFixed: "#4E4126",
  //         onSecondaryFixed: "#FFFFFF",
  //         secondaryFixedDim: "#372B12",
  //         onSecondaryFixedVariant: "#FFFFFF",
  //         tertiaryFixed: "#2F492D",
  //         onTertiaryFixed: "#FFFFFF",
  //         tertiaryFixedDim: "#193218",
  //         onTertiaryFixedVariant: "#FFFFFF",
  //         surfaceDim: "#E2D9CC",
  //         surfaceBright: "#FFF8F2",
  //         surfaceContainerLowest: "#FFFFFF",
  //         surfaceContainerLow: "#FCF2E5",
  //         surfaceContainer: "#F7ECDF",
  //         surfaceContainerHigh: "#F1E7D9",
  //         surfaceContainerHighest: "#EBE1D4"
  //     },
  //     dark: {
  //         primary: "#E9C16C",
  //         surfaceTint: "#E9C16C",
  //         onPrimary: "#402D00",
  //         primaryContainer: "#5B4300",
  //         onPrimaryContainer: "#FFDF9E",
  //         secondary: "#D8C4A0",
  //         onSecondary: "#3B2F15",
  //         secondaryContainer: "#52452A",
  //         onSecondaryContainer: "#F5E0BB",
  //         tertiary: "#B0CFA9",
  //         onTertiary: "#1D361C",
  //         tertiaryContainer: "#334D30",
  //         onTertiaryContainer: "#CCEBC4",
  //         error: "#FFB4AB",
  //         onError: "#690005",
  //         errorContainer: "#93000A",
  //         onErrorContainer: "#FFDAD6",
  //         background: "#17130B",
  //         onBackground: "#EBE1D4",
  //         surface: "#17130B",
  //         onSurface: "#EBE1D4",
  //         surfaceVariant: "#4D4639",
  //         onSurfaceVariant: "#D0C5B4",
  //         outline: "#998F80",
  //         outlineVariant: "#4D4639",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#EBE1D4",
  //         inverseOnSurface: "#353027",
  //         inversePrimary: "#785A0B",
  //         primaryFixed: "#FFDF9E",
  //         onPrimaryFixed: "#261A00",
  //         primaryFixedDim: "#E9C16C",
  //         onPrimaryFixedVariant: "#5B4300",
  //         secondaryFixed: "#F5E0BB",
  //         onSecondaryFixed: "#241A04",
  //         secondaryFixedDim: "#D8C4A0",
  //         onSecondaryFixedVariant: "#52452A",
  //         tertiaryFixed: "#CCEBC4",
  //         onTertiaryFixed: "#072008",
  //         tertiaryFixedDim: "#B0CFA9",
  //         onTertiaryFixedVariant: "#334D30",
  //         surfaceDim: "#17130B",
  //         surfaceBright: "#3E382F",
  //         surfaceContainerLowest: "#110E07",
  //         surfaceContainerLow: "#1F1B13",
  //         surfaceContainer: "#231F17",
  //         surfaceContainerHigh: "#2E2921",
  //         surfaceContainerHighest: "#39342B"
  //     },
  //     darkMediumContrast: {
  //         primary: "#EEC570",
  //         surfaceTint: "#E9C16C",
  //         onPrimary: "#1F1500",
  //         primaryContainer: "#AF8C3D",
  //         onPrimaryContainer: "#000000",
  //         secondary: "#DCC8A4",
  //         onSecondary: "#1E1501",
  //         secondaryContainer: "#A08F6E",
  //         onSecondaryContainer: "#000000",
  //         tertiary: "#B4D3AD",
  //         onTertiary: "#031B05",
  //         tertiaryContainer: "#7B9876",
  //         onTertiaryContainer: "#000000",
  //         error: "#FFBAB1",
  //         onError: "#370001",
  //         errorContainer: "#FF5449",
  //         onErrorContainer: "#000000",
  //         background: "#17130B",
  //         onBackground: "#EBE1D4",
  //         surface: "#17130B",
  //         onSurface: "#FFFAF7",
  //         surfaceVariant: "#4D4639",
  //         onSurfaceVariant: "#D5C9B8",
  //         outline: "#ACA291",
  //         outlineVariant: "#8B8273",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#EBE1D4",
  //         inverseOnSurface: "#2E2921",
  //         inversePrimary: "#5D4400",
  //         primaryFixed: "#FFDF9E",
  //         onPrimaryFixed: "#191000",
  //         primaryFixedDim: "#E9C16C",
  //         onPrimaryFixedVariant: "#473300",
  //         secondaryFixed: "#F5E0BB",
  //         onSecondaryFixed: "#191000",
  //         secondaryFixedDim: "#D8C4A0",
  //         onSecondaryFixedVariant: "#41351B",
  //         tertiaryFixed: "#CCEBC4",
  //         onTertiaryFixed: "#001602",
  //         tertiaryFixedDim: "#B0CFA9",
  //         onTertiaryFixedVariant: "#233C21",
  //         surfaceDim: "#17130B",
  //         surfaceBright: "#3E382F",
  //         surfaceContainerLowest: "#110E07",
  //         surfaceContainerLow: "#1F1B13",
  //         surfaceContainer: "#231F17",
  //         surfaceContainerHigh: "#2E2921",
  //         surfaceContainerHighest: "#39342B"
  //     },
  //     darkHighContrast: {
  //         primary: "#FFFAF7",
  //         surfaceTint: "#E9C16C",
  //         onPrimary: "#000000",
  //         primaryContainer: "#EEC570",
  //         onPrimaryContainer: "#000000",
  //         secondary: "#FFFAF7",
  //         onSecondary: "#000000",
  //         secondaryContainer: "#DCC8A4",
  //         onSecondaryContainer: "#000000",
  //         tertiary: "#F1FFEA",
  //         onTertiary: "#000000",
  //         tertiaryContainer: "#B4D3AD",
  //         onTertiaryContainer: "#000000",
  //         error: "#FFF9F9",
  //         onError: "#000000",
  //         errorContainer: "#FFBAB1",
  //         onErrorContainer: "#000000",
  //         background: "#17130B",
  //         onBackground: "#EBE1D4",
  //         surface: "#17130B",
  //         onSurface: "#FFFFFF",
  //         surfaceVariant: "#4D4639",
  //         onSurfaceVariant: "#FFFAF7",
  //         outline: "#D5C9B8",
  //         outlineVariant: "#D5C9B8",
  //         shadow: "#000000",
  //         scrim: "#000000",
  //         inverseSurface: "#EBE1D4",
  //         inverseOnSurface: "#000000",
  //         inversePrimary: "#382700",
  //         primaryFixed: "#FFE4AF",
  //         onPrimaryFixed: "#000000",
  //         primaryFixedDim: "#EEC570",
  //         onPrimaryFixedVariant: "#1F1500",
  //         secondaryFixed: "#F9E4BF",
  //         onSecondaryFixed: "#000000",
  //         secondaryFixedDim: "#DCC8A4",
  //         onSecondaryFixedVariant: "#1E1501",
  //         tertiaryFixed: "#D0F0C8",
  //         onTertiaryFixed: "#000000",
  //         tertiaryFixedDim: "#B4D3AD",
  //         onTertiaryFixedVariant: "#031B05",
  //         surfaceDim: "#17130B",
  //         surfaceBright: "#3E382F",
  //         surfaceContainerLowest: "#110E07",
  //         surfaceContainerLow: "#1F1B13",
  //         surfaceContainer: "#231F17",
  //         surfaceContainerHigh: "#2E2921",
  //         surfaceContainerHighest: "#39342B"
  //     }
  // },
  // palettes: {
  //     primary: {
  //         0: "#000000",
  //         5: "#180F00",
  //         10: "#261A00",
  //         15: "#322300",
  //         20: "#402D00",
  //         25: "#4D3800",
  //         30: "#5B4300",
  //         35: "#6A4E00",
  //         40: "#795900",
  //         50: "#987100",
  //         60: "#B68A0F",
  //         70: "#D4A42E",
  //         80: "#F2BF48",
  //         90: "#FFDF9E",
  //         95: "#FFEFD4",
  //         98: "#FFF8F2",
  //         99: "#FFFBFF",
  //         100: "#FFFFFF"
  //     },
  //     secondary: {
  //         0: "#000000",
  //         5: "#180F00",
  //         10: "#241A04",
  //         15: "#2F240C",
  //         20: "#3B2F15",
  //         25: "#463A1F",
  //         30: "#52452A",
  //         35: "#5F5134",
  //         40: "#6B5D3F",
  //         50: "#857556",
  //         60: "#A08F6E",
  //         70: "#BBA987",
  //         80: "#D8C4A0",
  //         90: "#F5E0BB",
  //         95: "#FFEFD4",
  //         98: "#FFF8F2",
  //         99: "#FFFBFF",
  //         100: "#FFFFFF"
  //     },
  //     tertiary: {
  //         0: "#000000",
  //         5: "#001502",
  //         10: "#082008",
  //         15: "#122B12",
  //         20: "#1D361C",
  //         25: "#284126",
  //         30: "#334D31",
  //         35: "#3F593B",
  //         40: "#4A6547",
  //         50: "#627E5E",
  //         60: "#7C9876",
  //         70: "#96B38F",
  //         80: "#B0CFA9",
  //         90: "#CCEBC4",
  //         95: "#DAF9D2",
  //         98: "#ECFFE4",
  //         99: "#F6FFF0",
  //         100: "#FFFFFF"
  //     },
  //     neutral: {
  //         0: "#000000",
  //         5: "#13110C",
  //         10: "#1E1B16",
  //         15: "#292520",
  //         20: "#34302A",
  //         25: "#3F3B35",
  //         30: "#4A4640",
  //         35: "#56524B",
  //         40: "#625E57",
  //         50: "#7C766F",
  //         60: "#969088",
  //         70: "#B1AAA2",
  //         80: "#CCC5BD",
  //         90: "#E9E1D9",
  //         95: "#F7EFE7",
  //         98: "#FFF8F2",
  //         99: "#FFFBFF",
  //         100: "#FFFFFF"
  //     },
  //     neutralVariant: {
  //         0: "#000000",
  //         5: "#151006",
  //         10: "#201B10",
  //         15: "#2B251A",
  //         20: "#363024",
  //         25: "#413B2E",
  //         30: "#4D4639",
  //         35: "#595144",
  //         40: "#655D4F",
  //         50: "#7F7667",
  //         60: "#998F80",
  //         70: "#B4AA99",
  //         80: "#D0C5B4",
  //         90: "#EDE1CF",
  //         95: "#FBEFDD",
  //         98: "#FFF8F2",
  //         99: "#FFFBFF",
  //         100: "#FFFFFF"
  //     }
  // },






  // colors: {
  //   text: "#F5F1EE",
  //   background: "#211F1C",
  //   surface1: "#2A2724",
  //   surface2: "#2C2A26",
  //   surface3: "#2D2A27",
  //   surface4: "#302D29",
  //   surface5: "#33302C",
  //   surface6: "#34312D",
  //   surface7: "#373430",
  //   surface8: "#393531",
  //   surface9: "#3B3733",
  //   highEmphasis: "rgba(255, 255, 255, 0.87)",
  //   mediumEmphasis: "rgba(255, 255, 255, 0.6)",
  //   disabled: "rgba(255, 255, 255, 0.12)",
  //   primary: "#f2bf48",
  //   onPrimary: "#402d00",
  //   primaryContainer: "#5b4300",
  //   onPrimaryContainer: "#ffdf9e",

  //   secondary: "#d8c4a0",
  //   onSecondary: "#3b2f15",
  //   secondaryContainer: "#52452a",
  //   onSecondaryContainer: "#f5e0bb",

  //   tertiary: "#56dbc5",
  //   onTertiary: "#003730",
  //   tertiaryContainer: "#005046",
  //   onTertiaryContainer: "#76f8e1",

  //   error: "#ffb4ab",
  //   onError: "#690005",
  //   errorContainer: "#93000a",
  //   onErrorContainer: "#ffdad6",

  //   outline: "#998f80",

  //   secondary30: "#52452a",

  //   neutral10: "#1e1b16",
  //   neutral80: "#ccc5bd",
  //   neutral90: "#e9e1d8",

  //   tertiaryMuted: "rgba(87,219,196, .4)",
  //   muted: "#f6f6f6",

  //   gold: "#6A5638",
  //   maroon: "#501214",
  //   sandstone: "#F5F1EE",
  //   blue: "#00507A",
  //   yellow: "#f2bf48",
  //   green: "#6EA095",
  //   charcoal: "#363534",
  //   red: "#ffb4ab",
  //   river: "#BFF3FD",
  //   peach: "#DB6450",
  //   veryDark: "#222222",
  //   darkGray: "#454545",
  //   mediumGray: "#595959",
  //   lightGray: "#d8d8d8",
  //   darkSandstone: "#ddd5c9",
  // },
  text: {},
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'regular',
    },
    '@global': {
      '@font-face': fontFaces,
    },
    p: {
      fontWeight: "body",
      // fontFamily:"Nunito Sans"
    },
    h1: {
      variant: "text.heading",
      fontSize: [4, 5],
      lineHeight: 1
    },
    h2: {
      variant: "text.heading",
      fontSize: [4],
      lineHeight: "heading",
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    a: {
      color: "tertiaryFixed",
      textDecoration: "none",
      ":visited": {
        color: "tertiary",
      },
      ":hover": {
        color: "onTertiaryContainer",
        textDecoration: "underline",
      },
      ":focus": {
        color: "onTertiaryContainer",
      },
    },
    contactList: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    contactListItemHeader: {
      justifyContent: "space-between",
      alignItems: "center",
      display: ["flex", "flex", "flex"],
      pb: [3, 0, 0],
      fontSize: 2,
      
    },
  },
  sizes: {
    container: 1028,
  },
  cards: {
    primary: {
      padding: [3, 4],
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      background: "surfaceContainer",
      color: "onSurface"
    },
    cardList: {
      // padding: [3, 4],
      // borderRadius: "12px",
      // boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      background: "surfaceContainer",
      px: 2,
      py: 3,
      color: "onSurface",
      // borderBottom:"1px solid red",
      // borderColor:"outline"
    },
    homepage: {
      padding: 0,
      borderRadius: "12px",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      background: "surfaceContainer",
      color: "onSurface"
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
    timeline: {
      position: "relative",
      margin: "0 0 20px 20px",
      padding: 4,
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      // backgroundColor: "surface1",
    },
    roadmap: {
      position: "relative",
      margin: "16px 16px 16px 16px",
      padding: [3, 4],
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
    beforeduringaftercards: {
      color: "darkSandstone",
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      backgroundColor: "surfaceContainerLow",
    },
    warning: {
      border: "0px solid",
      color: "onErrorContainer",
      borderColor: "none",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "errorContainer",
    },
    advisory: {
      // border: "2px solid",
      // borderColor: "none",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "errorContainer",
      color: "onErrorContainer"
    },
    no_alerts: {
      // border: "2px solid",
      // borderColor: "lightGray",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "secondaryContainer",
      color: "onSecondaryContainer",
    },
  },
  buttons: {
    primary: {
      fontFamily:'"Nunito Sans", sans-serif',
      minHeight:"44px",
      minWidth:"44px",
      fontSize: [1, 2],
      borderRadius: "24px",
      color: "onPrimary",
      border: "1px solid",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderColor: "primary",
      background: "primary",
      cursor: "pointer",
      transition: "background-color 0.1s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.15s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "0px 5px 6px 0px rgba(0, 0, 0, 0.4)",
        background: "primaryFixed",
        borderColor: "primaryFixed",
      },
    },
    secondary: {
      // fontFamily:"Nunito Sans",
      fontFamily:'"Nunito Sans", sans-serif',

      minHeight: "44px",
      minWidth: "44px",
      fontSize: [1, 2],
      borderRadius: "24px",
      border: "1px solid",
      borderColor: "outline",
      background: "none",
      color: "primary",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.15s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "0px 5px 6px 0px rgba(0, 0, 0, 0.4)",
        background: "surfaceContainerHigh",
      },
    },
    
    tertiary: {
      // fontFamily:"Nunito Sans",
      fontFamily:'"Nunito Sans", sans-serif',

      minHeight:"44px",
      minWidth:"44px",
      borderRadius: "24px",
      // marginLeft: -2,
      // px: 2,
      paddingTop: "11px",
      border: "1px solid red",
      borderColor: "outlineVariant",
      background: "transparent",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "primary",
      cursor: "pointer",
      transition: "color 0.15s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
        backgroundColor: "surfaceContainerHighest"

      },
    },
    alert: {
      // fontFamily:"Nunito Sans",
      fontFamily:'"Nunito Sans", sans-serif',

      minHeight:"44px",
      minWidth:"44px",
      borderRadius: "24px",
      // marginLeft: -2,
      // px: 2,
      // py: "11px",
      border: "none",
      background: "transparent",
      color: "onSurface",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
      },
    },
    chip: {
      // fontFamily:"Nunito Sans",
      fontFamily:'"Nunito Sans", sans-serif',

      minHeight:"44px",
      minWidth:"44px",
      fontSize: 0,
      borderRadius: "8px",
      backgroundColor: "outline",
      border: "1px solid",
      borderColor: "outlineVariant",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      py: 2,
      px: 3,
      fontWeight: 600,
      "&:hover": {
        background: "surfaceContainerHigh",
        color: "onSurface",
        cursor: "pointer"
      },
    },
    icon: {
      color: "#000",
      backgroundColor: "sandstone",
      fontSize: 4,
      padding: 0,
      marginTop: 3,
      "&:hover": {
        cursor: "pointer",
        color: "primary",
      },
    },
    back: {
      color: "white",
      backgroundColor: "background",
      fontSize: 4,
      padding: 0,
      marginTop: 2,
      verticalAlign: "top",
      "&:hover": {
        cursor: "pointer",
        color: "primary",
      },
    },
    previousLink: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      textAlign: "left",
      lineHeight: 1.2,
      padding: 3,
      mt: 3,
      width: "100%",
      borderRadius: "16px",
      backgroundColor: "surfaceContainerHighest",
      border: "0px solid",
      borderColor: "surfaceContainerHighest",
      color: "onSurface",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
        textDecoration: "none",
        backgroundColor: "secondaryContainer"
      },

      "& > span:nth-child(1)": {
        // Styles for the first span
        color: "secondary",
        fontSize: 1,
        marginBottom: 3,
        fontWeight: 700,
      },
      "& > span:nth-child(2)": {
        marginBottom: 2,
        color: "onSurface",
        fontSize: 1,
      },
      "& > span:nth-child(3)": {
        fontSize: 4,
      },
    },
    nextLink: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      alignContent: "center",
      textAlign: "right",
      // lineHeight: 1.2,
      padding: 3,
      mt: 3,
      width: "100%",
      borderRadius: "16px",
      border: "0px solid",
      color: "onSurface",
      cursor: "pointer",
      borderColor: "surfaceContainerHighest",
      backgroundColor: "surfaceContainerHighest",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
        backgroundColor: "secondaryContainer",
        textDecoration: "none"
      },

      // Add any other styles specific to the previous link variant
      "& > span:nth-child(1)": {
        // Styles for the first span
        color: "secondary",
        fontSize: 1,
        marginBottom: 3,
        fontWeight: 700,
      },
      "& > span:nth-child(2)": {
        marginBottom: 2,
        color: "onSurface",
        fontSize: 1,
      },
      "& > span:nth-child(3)": {
        fontSize: 4,
      },
    },
    tabs: {
      marginTop: "20px",
    },
    tabContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "0.5rem",
      borderTop: "1px solid #2c2a26",
      borderBottom: "1px solid #2c2a26",
      paddingLeft: "6px",
    },
    tab: {
      padding: "0.5rem 1rem",
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      outline: "none",
      fontSize: "1rem",
      fontWeight: "500",
      textTransform: "uppercase",
      transition: "color 0.3s",
      listStyle: "none",
      display: "inline",
    },
    activeTab: {
      color: "#f2bf48",
      borderBottom: "2px solid #f2bf48",
    },
    reactTabsTabPanel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "500px",
      margin: "0 auto",
    },
    reactTabsTabPanelDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "500px",
      margin: "0 auto",
    },
  },

});
